// vue
import { ref, reactive, computed, watch, onMounted, onBeforeUnmount } from 'vue';

// shared
import { BOTS_DATA_FORM_FACTORY, VA_PD_FILTER_FACTORY } from '@shared/factories';
import { formatUnexistedCurrsAsUnknownObject } from '@shared/util';

// router
import { useRoute, useRouter } from 'vue-router';

// store
import { useGl } from '@store/ts/gl';
import { useBots } from '@store/bots';
import { useRefs } from '@store/ts/refs';
import { useTools } from '@store/tools';

// services
import BotsService from'@services/bots';
import ApiKeysService from'@services/apiKeysService';
import WalletsService from '@services/walletsService';
import ExchangesService from '@services/exchangesService';
import TradeStatisticService from '@services/tradeStatisticService';

// i18n
import { useI18n } from 'vue-i18n';

// local services
import { GRID_ACTIONS, prepareSwitchTp, prepareStartFilters } from '@components/bots/services';

// shared
import { CRUD_PAGER_FACTORY, BOTS_DATA_FORM_FACTORY_PREPARE } from '@shared/factories';

// naive-ui
import {
    useNotification } from 'naive-ui';

export default function (props) {
    // store
    const gl = useGl();
    const bots = useBots();
    const refs = useRefs();
    const tools = useTools();

    // i18n
    const { t } = useI18n();

    // naive-ui
    const notification = useNotification();
    
    // router
    const route = useRoute();
    const router = useRouter();

    // vars
    const { _ } = window;
    const init = ref(false);
    const walletsRefs = ref();
    const apiKeysRefs = ref();
    const exchangeInfo = ref();
    const tickerMessage = ref();
    const showStartTime = ref(false);
    const dataForm = bots.dataForm;
    const symbolsForMarketRefs = ref();
    const currPresetName = ref('default');
    const pager = reactive(CRUD_PAGER_FACTORY());

    const pairsRefs = computed(() => exchangeInfo.value?.pairs || []);
    const isUpdating = computed(() => props.gridAction === GRID_ACTIONS.ACTION_EDIT);
    const pairSymbols = computed(() => String(dataForm.pair.value).split('/').map(s => s.trim()));
    const applyLabel = computed(() => dataForm?.id ? bots.localization['bot_update_button'] : bots.localization['bot_create_button']);
    const isExchangeFutures = computed(() => !!refs.exchanges.find(({ id }) => id === dataForm.exchange.value)?.futures);
    const exchangeCommissionCoin = computed(() => refs.exchanges.find(({ id }) => id === dataForm.exchange.value)?.commissionCoin || false);
    const isSimulate = computed(() => !!dataForm.settings.simulate);

    const leverageRefs = computed(() => exchangeInfo.value?.leverage || {});
    const leverageMinMax = computed(() => leverageRefs.value[dataForm.pair.value] || {});

    const coinSymbolForWallet = computed(() => {
        const [fSymbol, sSymbol] = pairSymbols.value;
        if (isExchangeCoin.value) return fSymbol;
        if (isExchangeFutures.value) return sSymbol;
        return dataForm.algo.value === 'long' ? sSymbol : (dataForm.algo.value === 'short' ? fSymbol : null);
    });

    const pairWalletsRefs = computed(() => {
        if (!walletsRefs.value || dataForm.algo.value == -1) return [];
        return walletsRefs.value.filter(w => w.currency == coinSymbolForWallet.value);
    });

    const recommendedPairsRef = computed(() => {
        return tools.pairsInfo.map(el => {
            return {
                id: el.pair,
                disabled: !~pairsRefs.value.findIndex(({ id }) => id === el.pair),
                ...el,
            };
        });
    });

    const exchangeMeta2Info = computed(() => exchange(dataForm.exchange.value)?.meta2);
    const exchangeMeta3Info = computed(() => exchange(dataForm.exchange.value)?.meta3);

    const isExchangeSelected = computed(() => dataForm.exchange.value !== -1);
    const isPairSelected = computed(() => dataForm.pair.value !== -1);
    const isApiKeySelected = computed(() => dataForm.api_key.value !== -1);
    const isAlgoSelected = computed(() => dataForm.algo.value !== -1);
    const isExchangeCoin = computed(() => exchangeInfo.value?.extra.coin == 1);
    const isWalletSelected = computed(() => dataForm.wallet.value !== -1);
    const canCreateBot = computed(() => isAlgoSelected.value && dataForm.name.value.trim().length > 0);

    const algoOptions = computed(() => bots.algos.map(el => ({
        label: el.title,
        value: el.id,
        ...el,
    })));

    const exchangesSorted = computed(() => {
        const data = Object.values(refs.exchanges)
            .sort((e1, e2) => e1.title > e2.title ? 1 : -1);

        return data.map(el => ({
            value: el.id,
            label: el.title,
            ...el,
        }));
    });

    watch(() => dataForm.settings?.simulate, v => {
        if (v) {
            dataForm.api_key.value = 0;   
        }
    });

    watch(() => dataForm.start_filters?.length, v => {
        dataForm.start_filters_enabled = v > 0;
    });

    watch(() => dataForm.exchange?.value, async v => {
        if (!init.value) return;

        setLoading('exchange');
  
        exchangeInfo.value = null;
  
        dataForm.api_key.value = dataForm.settings.simulate ? 0 : -1;
        apiKeysRefs.value = null;
  
        dataForm.pair.value = -1;
        dataForm.algo.value = -1;
  
        dataForm.wallet.value = -1;
        walletsRefs.value = null;
        dataForm.leverage.value = 0;
        dataForm.algo_autoinversion_enabled = false;
      
        if (v != -1) {
            try {
                // get Api Keys for this exchange
                await getApiKeysList(v);

                // get Pairs for this Exchange
                exchangeInfo.value = ( await ExchangesService.getFullInfo(v) ).data;
            } catch {
                return void gl.showNotification({
                    type: 'error',
                    msg: t('errorMessage'),
                });
            };

            dataForm.comm_type.value = exchangeCommissionCoin.value ? 'internal' : 'standard';
        }

        setLoading('exchange', false);
    });

    watch(() => dataForm.api_key?.value, async v => {
        // if (!dataForm.api_key.show || !init.value) return;

        // setLoading('api_key');
  
        // dataForm.wallet.value = -1;
        // dataForm.leverage.value = 0;

        // if (v != -1 && dataForm.pair.value != -1) {
        //     try {
        //         walletsRefs.value = ( await WalletsService.walletsGet({
        //             pager: CRUD_PAGER_FACTORY({
        //                 exchanges: [dataForm.exchange.value],
        //                 apikeys: [v],
        //                 pairs: [dataForm.pair.value],
        //             })}) ).data.records;

        //         if (isWalletSelected.value) {
        //             const i = walletsRefs.value.findIndex(({ id }) => id == dataForm.wallet.value);

        //             ~i
        //                 ? dataForm.wallet.value = walletsRefs.value[i].id
        //                 : dataForm.wallet.value = -1;
        //         }
        //     } catch {
        //         gl.showNotification({
        //             type: 'error',
        //             msg: t('errorMessage'),
        //         });
        //         dataForm.wallet.value = -1;
        //     }
        // }
        // setLoading('api_key', false);
    });

    watch(() => dataForm.pair?.value, async v => {
        if (!init.value || !dataForm.pair.show) return;

        setLoading('pair');

        if (isExchangeSelected.value && isPairSelected.value) {
            TradeStatisticService.getTickerMessage({
                exchange_id: dataForm.exchange.value,
                pair: v,
            })
                .then(({ message }) => {
                    tickerMessage.value = message;
                })
                .catch(() => {
                    gl.showNotification({
                        type: 'error',
                        msg: t('errorMessage'),
                    });
                });
        } else {
            tickerMessage.value = null;
        }

        dataForm.algo.value = -1;
        walletsRefs.value = null;
        dataForm.leverage.value = 0;

        if (v != -1 && dataForm.api_key.value != -1)
            try {
                walletsRefs.value = ( await WalletsService.walletsGet({
                    pager: CRUD_PAGER_FACTORY({
                        exchanges: [dataForm.exchange.value],
                        pairs: [v],
                    })})).data.records;

                if (isWalletSelected.value)
                    dataForm.wallet.value = +v;
            } catch {
                gl.showNotification({
                    type: 'error',
                    msg: t('errorMessage'),
                });
                dataForm.pair.loading = false;
            };
      
        // await this.resetAutoInvRefs(newVal)
      
        // this.dataForm.settings.profit_coin = this.profitCoin
      
        // await this.root.installHelp(this.$rbBotForm, this.VMN)

        setLoading('pair', false);
    });

    watch(() => dataForm.settings?.profit_coin, async newVal => {
        if (!newVal || newVal == -1 || !init.value) return;

        try {
            var prepare = ( await ExchangesService.getSymbolsForMarket({
                exchange: dataForm.exchange.value,
                market: newVal,
            }) ).data;
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        }

        symbolsForMarketRefs.value = formatUnexistedCurrsAsUnknownObject(refs, prepare.symbols || []);
    });

    watch(() => dataForm.algo?.value, () => {
        if (!init.value) return;

        dataForm.settings.profit_coin = coinSymbolForWallet.value;
        dataForm.wallet.value = -1;
    });

    watch(() => dataForm.algo_autoinversion_enabled, v => {
        if (!init.value) return;

        if (v)
            dataForm.switch_tp.enabled = false;
    });

    watch(() => dataForm.switch_tp?.enabled, v => {
        if (!init.value) return;

        if (v)
            dataForm.algo_autoinversion_enabled = false;
    });

    watch(() => dataForm.api_key?.show, v => {
        // if (v && init.value) {
        //     setTimeout(() => {
        //         const top = document.querySelector('#api_key').getBoundingClientRect().top;
        //         document.querySelector('#main_container').scrollTo({
        //             top,
        //             behavior: 'smooth',
        //         });
        //     }, 200);
        // };
    });

    watch(() => dataForm.pair?.show, v => {
        if (v && init.value) {
            setTimeout(() => {
                const top = document.querySelector('#pair').getBoundingClientRect().top;
                document.querySelector('#main_container').scrollTo({
                    top,
                    behavior: 'smooth',
                });
            }, 200);
        };
    });

    watch(() => dataForm.algo?.show, v => {
        if (v && init.value) {
            setTimeout(() => {
                const top = document.querySelector('#algo').getBoundingClientRect().top;
                document.querySelector('#main_container').scrollTo({
                    top,
                    behavior: 'smooth',
                });
            }, 200);
        };
    });

    watch(() => dataForm.wallet?.show, v => {
        if (v && init.value) {
            setTimeout(() => {
                document.querySelector('#main_container').scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
            }, 200);
        };
    });

    const initModule = (obj = null) => {
        let res;

        if (!obj) {
            const preset = bots.presets[currPresetName.value]?.settings || {};
            res = reactive(BOTS_DATA_FORM_FACTORY(preset, bots.notifications));
        } else {
            res = obj;
        };
      
        for (const [key, value] of Object.entries(res)) {
            if (key === 'name') {
                dataForm[key] = {
                    value,
                    title: bots.localization['bot_name_f'],
                    dataHelp: bots.help['bot_name'],
                    placeholder: bots.localization['bot_name_i'],
                    status: undefined,
                    msg: undefined,
                    loading: false,
                    show: true,
                    customField: true,
                };
            } else if (key === 'exchange') {
                dataForm[key] = {
                    value,
                    title: bots.localization['exchange_f'],
                    dataHelp: bots.help['exchange'],
                    placeholder: `- ${t('select')} -`,
                    status: undefined,
                    msg: undefined,
                    loading: false,
                    show: true,
                    customField: true,
                };
            } else if (key === 'api_key') {
                dataForm[key] = {
                    value,
                    title: bots.localization['apikey_f'],
                    dataHelp: bots.help['api_key'],
                    placeholder: `- ${t('select')} -`,
                    status: undefined,
                    msg: undefined,
                    loading: false,
                    show: computed(() => !!(isExchangeSelected.value && apiKeysRefs.value && !isSimulate.value)),
                    buttonLabel: bots.localization['bots_create_new_api_key'],
                    customField: true,
                };
            } else if (key === 'pair') {
                dataForm[key] = {
                    value,
                    title: bots.localization['bot/recommended_pairs/title'],
                    label: bots.localization['pair_f'],
                    howToChooseInfo: bots.localization['bots_how_to_choose_trade_pair'],
                    gridNavLabel: bots.localization['bot/recommended_pairs/show_per_page'],
                    recommendedPairsInfo: bots.localization['bot/recommended_pairs/info'],
                    pager: CRUD_PAGER_FACTORY(),
                    dataHelp: bots.help['pair'],
                    placeholder: `- ${t('select')} -`,
                    status: undefined,
                    msg: undefined,
                    loading: false,
                    show: computed(() => !!(isApiKeySelected.value && exchangeInfo.value)),
                    customField: true,
                };
            } else if (key === 'algo') {
                dataForm[key] = {
                    value,
                    title: bots.localization['algo_f'],
                    info: bots.localization['bots_bot_algorithms_info'],
                    dataHelp: bots.help['algo'],
                    placeholder: `- ${t('select')} -`,
                    status: undefined,
                    msg: undefined,
                    loading: false,
                    show: computed(() => !!(isPairSelected.value && bots.algos)),
                    customField: true,
                };
            } else if (key === 'wallet') {
                dataForm[key] = {
                    value,
                    title: bots.localization['depo_f'],
                    dataHelp: bots.help['depo'],
                    placeholder: `- ${t('select')} -`,
                    status: undefined,
                    msg: undefined,
                    loading: false,
                    show: computed(() => !!(isAlgoSelected.value && walletsRefs.value)),
                    buttonCreateLabel: bots.localization['bots_create_new_wallet'],
                    buttonCheckLabel: bots.localization['bots_check_balance'],
                    buttonCheckDisabled: computed(() => !isWalletSelected.value),
                    buttonEditLabel: bots.localization['bots_edit_wallet'],
                    buttonEditDisabled: computed(() => !isWalletSelected.value),
                    walletsRefs: computed(() => walletsRefs.value),
                    customField: true,
                };
            } else if (key === 'comm_type') {
                dataForm[key] = {
                    value,
                    label: bots.localization['commission_f'],
                    dataHelp: bots.help['commission'],
                    customField: true,
                };
            } else if (key === 'leverage') {
                dataForm[key] = {
                    value,
                    label: bots.localization['bot/leverage/label'],
                    info: bots.localization['bot/leverage/info'],
                    customField: true,
                };
            } else {
                dataForm[key] = value;
            }
        };

        setTimeout(() => {
            init.value = true;
        }, 0);
    };

    const exchange = (exchange) => {
        return refs.exchanges.find(el => el.id === exchange);
    };

    const getApiKeysList = async (id = dataForm.exchange.value) => {
        apiKeysRefs.value = ( await ApiKeysService.getApiKeysList({
            exchanges: [id],
            statuses: [1],
        }) ).data.records;
    };

    const addNewWallet = async $event => {
        setLoading('wallet');
        setLoading('api_key');

        walletsRefs.value = ( await WalletsService.getWalletsList({
            exchanges: [dataForm.exchange.value],
            pairs: [dataForm.pair.value],
        }) ).data.records;

        const i = walletsRefs.value.findIndex(({ id }) => id == $event.id);

        if (~i) {
            dataForm.wallet.value = +$event.id;
            dataForm.api_key.value = $event.api_key;
        }

        setLoading('wallet', false);
        setLoading('api_key', false);
    };

    const updateWallet = $event => {
        setLoading('wallet');

        dataForm.api_key.value = $event.api_key;

        if (dataForm.api_key.value === $event.api_key) {
            const i = walletsRefs.value.findIndex(({ id }) => id == $event.id);

            if (~i)
                walletsRefs.value[i] = $event;
        } else {
            dataForm.api_key.value = $event.api_key;
        }

        setLoading('wallet', false);
    };

    const setLoading = (key, v = true) => {
        const i = Object.keys(dataForm).findIndex(el => el === key);

        if (~i)
            Object.values(dataForm).slice(i).forEach(el => {
                if (typeof el === 'object' && el !== null) {
                    if (Object.prototype.hasOwnProperty.call(el, 'loading'))
                        el.loading = v;
                }
            });
    };

    const updateSettings = $event => {
        dataForm.settings[$event[0]] = $event[1];
    };

    const onDeletePDFilter = i => {
        dataForm.switch_tp.pd_filters.splice(i, 1);
    };

    const onAddPDFilter = () => {
        dataForm.switch_tp.pd_filters.push(VA_PD_FILTER_FACTORY());
    };

    const updatePDFilter = ([ $event, i, field ]) => {
        dataForm.switch_tp.pd_filters[i][field] = +$event;
    };

    const onStoItemClicked = symbol => {
        dataForm.switch_tp.user_list.splice(dataForm.switch_tp.user_list.indexOf(symbol), 1);
    };

    const spreadSwitchTp = $event => {
        dataForm.switch_tp = { ...dataForm.switch_tp, ...$event };
    };

    const onApplyClicked = async () => {
        gl.showLoading = true;

        const payload = BOTS_DATA_FORM_FACTORY_PREPARE(dataForm);
        payload.start_filters.forEach(el => {
            delete el.parent_id;
            delete el.changed;
        });
        payload.start_filters = prepareStartFilters(payload);
        payload.switch_tp = prepareSwitchTp(payload);
        payload['_formPath'] = 'bots.addNew';

        try {
            bots.clearErrors();

            const result = await BotsService.addNew({
                pager,
                records: [payload],
            });

            if (result.postMessages) {
                result.postMessages.forEach(el => {
                    notification[el.success ? 'success' : 'error']({
                        content: el.msg,
                        duration: 2500,
                        keepAliveOnHover: true,
                    });
                });
            };

            if (!result.data.status) {
                if (result.data.errors_form) {
                    if (Object.keys(result.data.errors_form['bots.addNew'].fields).length) {
                        bots.errorsForm = result.data.errors_form['bots.addNew'].fields;
    
                        setTimeout(() => {
                            const selector = Object.keys(bots.errorsForm)[0];
        
                            const el = document.querySelector(`#${selector}`);
                            if (el)
                                el.scrollIntoView({ block: gl.isMobile ? 'start' : 'center', behavior: 'smooth' });
                        });
                    } else if (Object.keys(result.data.errors_form['bots.addNew']?.innerForms).length) {
                        bots.setInnerForms({
                            data: result.data.errors_form['bots.addNew'].innerForms,
                            startFilters: payload.start_filters,
                        });
                    }
                }
            } else {
                const newBot = await BotsService.get({
                    pager: CRUD_PAGER_FACTORY({
                        id: [result.data.records[0].id],
                    }),
                });

                if (bots.dataTable?.records)
                    bots.dataTable.records.unshift(newBot.data.records[0]);

                // if (result.postMessages) {
                //     result.postMessages.forEach(el => {
                //         notification[el.success ? 'success' : 'error']({
                //             content: el.msg,
                //             duration: 2500,
                //             keepAliveOnHover: true,
                //         });
                //     });
                // };

                router.push({ name: 'bots.review' });
            }
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        };

        gl.showLoading = false;
    };

    const getSymbolsForMarket = async ({ exchange, market }) => {
        try {
            var prepare = ( await ExchangesService.getSymbolsForMarket({
                exchange,
                market,
            }) ).data;

            symbolsForMarketRefs.value = formatUnexistedCurrsAsUnknownObject(refs, prepare.symbols || []);
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        }
    };

    const getExchangeFullInfo = async (id = dataForm.exchange.value) => {
        try {
            return ( await ExchangesService.getFullInfo(id) ).data;
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        };
    };

    const getWalletsRefs = async ({ exchanges, apikeys, pairs }) => {
        try {
            return ( await WalletsService.getWalletsList({
                exchanges: [exchanges],
                apikeys: [apikeys],
                pairs: [pairs],
            }) ).data.records;
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        };
    };

    const getFullInfo = async id => {
        gl.showLoading = true;

        try {
            const result = await BotsService.getFullInfo(id);

            if (!result.data.status) {
                result.data.errors.forEach(({ msg }) => {
                    gl.showNotification({
                        type: 'error',
                        msg,
                    });
                });
                router.push({ name: 'bots.review' });
            } else {
                const prepare = await Promise.allSettled([
                    getSymbolsForMarket({
                        exchange: result.data.record.exchange,
                        market: result.data.record.settings.profit_coin,
                    }),
                    getExchangeFullInfo(result.data.record.exchange),
                    getApiKeysList(result.data.record.exchange),
                    getWalletsRefs({
                        exchanges: result.data.record.exchange,
                        apikeys: -1,
                        pairs: result.data.record.pair,
                    }),
                ]);

                exchangeInfo.value = prepare[1].value;
                walletsRefs.value = prepare[3].value;

                initModule(result.data.record);

                // dataFormPrepare(data.data.record);
            }
        } catch  {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        }
      
        gl.showLoading = false;
    };

    const triggerFilter = id => {
        const localId = hasFilter(id);

        if(~localId) {
            dataForm.start_filters.splice(localId, 1);
        } else {
            const date = new Date();
            
            const { year, month, day, hours, minutes, seconds } = getDateCompoents(date);

            dataForm.start_filters.push({
                id,
                id_op: '==',
                value: `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`,
                parent_id: -1,
                changed: false,
            });
        }
    };

    const hasFilter = id => {
        return dataForm.start_filters.findIndex(el => id == el.id);
    };

    const getDateCompoents = date => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1) < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
        const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();

        const hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
        const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
        const seconds = date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds();

        return {
            year,
            month,
            day,
            hours,
            minutes,
            seconds,
        };
    };

    const setStartTime = (index, $event) => {
        const date = new Date($event);
        const { year, month, day, hours, minutes, seconds } = getDateCompoents(date);

        // const time = dataForm.start_filters[index].value.split(' ')[1];

        dataForm.start_filters[index].value = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };

    const setApiKey = $event => {
        dataForm.api_key.value = $event.id;
        apiKeysRefs.value.push($event);
    };

    onMounted(async () => {
        await getFullInfo(route.params.id);
    });

    onBeforeUnmount(() => {
        bots.dataForm = {};
    });

    return {
        gl,
        bots,
        init,
        pager,
        router,
        dataForm,
        pairsRefs,
        isSimulate,
        isUpdating,
        applyLabel,
        algoOptions,
        walletsRefs,
        apiKeysRefs,
        exchangeInfo,
        canCreateBot,
        tickerMessage,
        showStartTime,
        currPresetName,
        leverageMinMax,
        exchangesSorted,
        pairWalletsRefs,
        isWalletSelected,
        exchangeMeta2Info,
        exchangeMeta3Info,
        isExchangeFutures,
        recommendedPairsRef,
        coinSymbolForWallet,
        symbolsForMarketRefs,
        exchangeCommissionCoin,
        t,
        setApiKey,
        exchange,
        hasFilter,
        setStartTime,
        addNewWallet,
        updateWallet,
        triggerFilter,
        onAddPDFilter,
        onApplyClicked,
        spreadSwitchTp,
        updateSettings,
        getApiKeysList,
        updatePDFilter,
        onDeletePDFilter,
        onStoItemClicked,
        dateDisabled(ts) {
            return new Date(ts).getTime() < (new Date().getTime() - 86400000 * 60) || new Date(ts).getTime() > new Date().getTime();
        },
    };
}